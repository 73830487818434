import fontMap from '../../packages/ui/src/elements/ThemeProvider/fonts/font'

let active = false
let loaded = false
export const isMyFontsActive = () => {
  if (process.env.NODE_ENV !== 'production') {
    return false
  }

  return active
}

export const isMyFontsLoaded = () => {
  return loaded
}

//language=CSS
const style = `
@font-face {
  font-family: "Helvetica Neue";
  font-display: swap;
  font-weight: 400;
  src: url('${fontMap.helvetica400.woff2}') format('woff2'), url('${fontMap.helvetica400.woff}') format('woff');
}
@font-face {
  font-family: "Helvetica Neue";
  font-display: swap;
  font-weight: 500;
  src: url('${fontMap.helvetica500.woff2}') format('woff2'), url('${fontMap.helvetica500.woff}') format('woff');
}
@font-face {
  font-family: "Helvetica Neue";
  font-display: swap;
  font-weight: bold;
  src: url('${fontMap.helvetica600.woff2}') format('woff2'), url('${fontMap.helvetica600.woff}') format('woff');
}
`

//language=CSS
const prodStyle = `
@import url("//hello.myfonts.net/count/3fb600");
${style}
`

const loadStyle = (css: string) => {
  const styleElem = document.createElement('style')
  styleElem.setAttribute('type', 'text/css')
  styleElem.appendChild(document.createTextNode(css))

  document.head.append(styleElem)
}

const loadDevMode = () => {
  loadStyle(style)
  loaded = true
}

export const loadMyFonts = () => {
  if (process.env.NODE_ENV !== 'production' || process.env.GATSBY_PREVIEW || process.env.GATSBY_FORCE_FONTS) {
    loadDevMode()
    return
  }

  active = true

  if (!isMyFontsActive() || isMyFontsLoaded()) {
    return
  }

  loaded = true

  loadStyle(prodStyle)
}
